import React, { Component } from 'react'
import {inject} from 'mobx-react'
import Loader from '../../modules/common/Loader'

@inject('appStore')
class Logout extends Component {
  componentDidMount() {
    const {appStore} = this.props

    appStore.logout().then(r => {
      this.props.history.push('/')
    }).catch(e => {
      this.props.history.push('/')
    })
  }

  render () {
    return <Loader />
  }
}

export default Logout
