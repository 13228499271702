import React, {Component} from 'react'
import styles from './SocialRow.module.scss'
import facebook from './images/facebook.svg'
import google from './images/google.svg'

class SocialRow extends Component {
  render () {
    const { data } = this.props

    const image = data.provider === 'google-oauth2' ? google : facebook

    return (
      <div className={styles.SocialRow}>
        <img src={image} />
        <div className={styles.SocialRow__info}>
          <div className={styles.SocialRow__top}>{data.uid.indexOf('@') ? 'Email' : 'ID'}</div>
          <div className={styles.SocialRow__value}>{data.uid}</div>
        </div>
      </div>
    )
  }
}

export default SocialRow
