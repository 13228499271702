import React, {Component} from 'react'
import styles from './CookiesAlert.module.scss'
import Button from '../Button'
import { inject } from 'mobx-react'

@inject('appStore')
class CookiesAlert extends Component {
  render () {
    const {appStore} = this.props

    return (
      <div className={styles.CookiesAlert}>
        <div className={styles.CookiesAlert__text}>
          We use cookies to help operate our website and make your experience better. By continuing on our site, you're giving us consent to feed your browser cookies. <a href='https://moletunnels.net/terms/' target="_blank">Learn more</a>.
        </div>
        <Button size={'small'} title={'got it'} onClick={() => appStore.hideCookiesAlert()}/>
      </div>
    )
  }
}

export default CookiesAlert
