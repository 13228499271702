import React, {Component} from 'react'
import styles from './Navigation.module.scss'

class Navigation extends Component {
  render () {
    const {selected = 'pricing'} = this.props

    const links = [{
      title: 'Pricing',
      url: 'https://moletunnels.net/pricing/'
    }, {
      title: 'Download',
      url: 'https://chrome.google.com/webstore/detail/ioioabbadhlnjojnhhheddlbhjmmbfhd'
    }]

    return (
      <nav className={styles.Navigation}>
        {links.map(link => <a href={link.url} target={'_blank'} className={styles['Navigation__link']}>{link.title}</a>)}
      </nav>
    )
  }
}

export default Navigation
