import React, {Component} from 'react'
import styles from './RoundedButton.module.scss'

class RoundedButton extends Component {
  render () {
    const {title, style, onClick} = this.props

    return (
      <div className={styles.RoundedButton} style={style} onClick={onClick}>
        {title}
      </div>
    )
  }
}

export default RoundedButton
