import React, {Component} from 'react'
import styles from './Error.module.scss'

class Error extends Component {
  render () {
    return <div className={styles.Error}>
      {this.props.text}
    </div>
  }
}

export default Error
