import React, {Component} from 'react'
import styles from './Blocked.module.scss'

class Blocked extends Component {
  render () {
    let reason

    if (this.props.reason === 'device_limit') {
      reason = 'Device limit reached'
    } else if (this.props.reason === 'traffic_limit') {
      reason = 'Traffic limit reached'
    }

    return (
      <div className={styles.Blocked}>
        <div className={styles.Blocked__top}>Your account is disabled</div>
        <div className={styles.Blocked__bottom}>Reason: {reason}. <a href={'https://moletunnels.net/pricing/'}>Upgrade</a></div>
      </div>
    )
  }
}

export default Blocked
