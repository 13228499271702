/*global chrome*/
import { types } from 'mobx-state-tree'
import axios from 'axios'
import User from './User'

const BaseUrl = 'https://moletunnels.net/api/v1'
//const BaseUrl = 'http://127.0.0.1:8000/api/v1'

const Tariff = types
.model('Tariff', {
  id: types.maybe(types.number),
  name: types.optional(types.string, ''),
  time_limit: types.optional(types.number, 0),
  in_limit: types.optional(types.number, 0),
  out_limit: types.optional(types.number, 0),
  total_limit: types.optional(types.number, 0),
})
.views(self => ({

}))
.actions(self => ({

}))

function getCookie (name) {
  let cookieValue = null
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()

      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
        break
      }
    }
  }
  return cookieValue
}


const AppStore = types
.model('AppStore', {
  loading: types.optional(types.boolean, true),
  user: types.optional(User, {}),
  tariffs: types.optional(types.array(Tariff), []),
  token: types.optional(types.string, ''),
  isCookiesAlertShown: types.optional(types.boolean, false),
  error: types.optional(types.string, '')
})
.views(self => ({
  get isUserLoggedIn() {
    return !!self.user.email
  },
}))
.actions(self => ({
  setUser(data) {
    self.user = data
  },

  setUserDevice(data) {
    self.user.devices = data
  },

  setUserPayments(data) {
    self.user.payments = data
  },

  setTariffs(data) {
    self.tariffs = data
  },

  getTariffNameById(id) {
    let name = ''

    self.tariffs.forEach(tariff => {
      if (tariff.id === id) {
        name = tariff.name
      }
    })

    return name
  },

  setLoading(value) {
    self.loading = value
  },

  login(email, password) {
    return self.post('/accounts/login/', {
      login:email,
      password
    })
  },

  loginOAuth(provider, code, redirect_uri) {
    return self.post('/accounts/login/social/token/', {
      provider,
      code,
      redirect_uri
    }).catch(e => self.setError('Something went wrong or email is already in use...'))
  },

  recover(email) {
    return self.post('/accounts/send-reset-password-link/', {
      login: email
    })
  },

  setPassword(password, params) {
    return self.post('/accounts/reset-password/', {
      ...params,
      password
    })
  },

  manageSubscriptions() {
    return self.get('/users/current/billing_portal/')
  },

  changePassword(params) {
    return self.post('/accounts/change-password/', params)
  },

  signup(email, password, password_confirm) {
    return self.post('/accounts/register/', {
      email,
      username: email,
      password,
      password_confirm
    })
  },

  confirm(params) {
    return self.post('/accounts/verify-registration/', params)
  },

  logout() {
    self.user = {}

    return self.post('/accounts/logout/').then(r => {
      self.setToken('')
    })
  },

  setToken(token) {
    self.token = token
    localStorage.setItem('token', token)
  },

  deleteDevice(id) {
    self.delete('/users/current/devices/'+id+'/').then(r => {
      const devices = self.user.devices.filter(device => device.id !== id)
      self.setUserDevice(devices)
    })
  },

  getCurrentUser() {
    return self.get('/users/current/').then(async r => {
      self.setUser(r.data)

      try {
        await self.get('/users/current/devices/').then(r => {
          self.setUserDevice(r.data)
        })
      } catch(e) {

      }



      try {
        await self.get('/users/current/payments/').then(r => {
          self.setUserPayments(r.data)
        })
      } catch(e) {

      }


      self.setLoading(false)

      try {
        chrome.runtime.sendMessage('ioioabbadhlnjojnhhheddlbhjmmbfhd', {
          loginToken: self.token
        })
      } catch(e) {}
    }).catch((e) => {
      if (e.response && e.response.data && e.response.data.detail) {
        self.setError(e.response.data.detail)
      }

      self.setToken('')
      self.setLoading(false)
    })
  },

  setError(text) {
    self.error = text

    setTimeout(() => {
      self.clearError()
    }, 3000)
  },

  clearError() {
    self.error = ''
  },

  removeDevice(id) {
    self.user.devices = self.user.devices.filter(d => {
      return d.id !== id
    })

    self.delete('/users/current/devices/'+id+'/')
  },

  hideCookiesAlert () {
    self.isCookiesAlertShown = false
    localStorage.setItem('isCookiesAlertHide', 'true')
  },

  async afterCreate() {
    self.isCookiesAlertShown = !localStorage.getItem('isCookiesAlertHide')

    const token = localStorage.getItem('token')

    try {
      await self.get('/tariffs/').then(r => {
        self.setTariffs(r.data)
      })
    } catch(e) {}

    if (token) {
      self.setToken(token)
      self.getCurrentUser()
    } else {
      self.setLoading(false)
    }
  }
}))
.actions(self => ({
  post: async function(url, params) {
    let headers = {
      'Content-Type': 'application/json'
    }

    if (self.token) {
      headers['Authorization'] = 'Token ' + self.token
    }

    const csrftoken = getCookie('csrftoken')

    if (csrftoken) {
      headers['X-CSRFToken'] = csrftoken
    }

    return axios.post(BaseUrl + url, params,
      {
        withCredentials: true,
        headers
      })
  },

  patch: async function(url, params) {
    let headers = {
      'Content-Type': 'application/json'
    }

    if (self.token) {
      headers['Authorization'] = 'Token ' + self.token
    }

    const csrftoken = getCookie('csrftoken')

    if (csrftoken) {
      headers['X-CSRFToken'] = csrftoken
    }

    return axios.patch(BaseUrl + url, params,
      {
        withCredentials: true,
        headers
      })
  },

  put: async function(url, params) {
    let headers = {
      'Content-Type': 'application/json'
    }

    if (self.token) {
      headers['Authorization'] = 'Token ' + self.token
    }

    const csrftoken = getCookie('csrftoken')

    if (csrftoken) {
      headers['X-CSRFToken'] = csrftoken
    }

    return axios.put(BaseUrl + url, params,
      {
        withCredentials: true,
        headers
      })
  },

  get: async function(url, params) {
    let headers = {}

    if (self.token) {
      headers['Authorization'] = 'Token ' + self.token
    }

    const csrftoken = getCookie('csrftoken')

    if (csrftoken) {
      headers['X-CSRFToken'] = csrftoken
    }

    return axios.get(BaseUrl + url, {
      withCredentials: true,
      headers,
      params
    })
  },

  delete: async function(url, params, body) {
    let headers = {
      'Content-Type': 'application/json'
    }

    if (self.token) {
      headers['Authorization'] = 'Token ' + self.token
    }

    const csrftoken = getCookie('csrftoken')

    if (csrftoken) {
      headers['X-CSRFToken'] = csrftoken
    }

    return axios.delete(BaseUrl + url,
      {
        withCredentials: true,
        headers,
        params,
        data: body
      })
  }
}))

const appStore = AppStore.create({})
export default appStore
