import React, {Component} from 'react'
import styles from './Popup.module.scss'
import logoGreen from './images/logo_green.svg'
import logoRed from './images/logo_red.svg'
import logoYellow from './images/logo_yellow.svg'

class Popup extends Component {
  render () {
    const {title, children, footer, type, afterText} = this.props

    let image

    switch (type) {
      case 'red':
        image = logoRed
        break
      case 'yellow':
        image = logoYellow
        break
      default:
        image = logoGreen
        break
    }

    return (
      <div className={styles.Popup}>
        {title && <div className={styles.Popup__header}>
          <h2 className={styles.Popup__title}>{title}</h2>
          <img src={image} />
        </div>}
        <div className={styles.Popup__body}>
          {children}
        </div>
        {footer && <div className={styles.Popup__footer}>
          <p>{footer}</p>
        </div>}
        {afterText && <div className={styles.Popup__afterText}>
          {afterText}
        </div>}
      </div>
    )
  }
}

export default Popup
