import React, {Component} from 'react'
import styles from './Header.module.scss'
import Logo from '../Logo'
import Navigation from '../Navigation'
import RoundedButton from '../RoundedButton'
import { observer } from 'mobx-react'
import { observable } from 'mobx'

@observer
class Header extends Component {
  @observable active = false

  render () {
    return (
      <div className={this.active ? styles.Header_active : styles.Header}>
        <div className={styles.Header__hamburger} onClick={() => this.active = !this.active}><div /><div /><div /></div>
        <Logo />
        <div className={styles.Header__right}>
          <Navigation/>
          {/*<RoundedButton title={'Upgrade'} style={{marginLeft: 40}} onClick={() => {*/}
          {/*  window.open('https://moletunnels.net/pricing/')*/}
          {/*}} />*/}
        </div>
      </div>
    )
  }
}

export default Header
