import React, {Component} from 'react'
import styles from './Table.module.scss'
import { inject } from 'mobx-react'

@inject('appStore')
class Table extends Component {
  render () {
    const { fields, rows, appStore } = this.props

    return (
      <table className={styles.Table}>
        <tr>
          {fields.map(field => <th>{field.title}</th>)}
        </tr>
        {rows.map(row => <tr>
          {fields.map(field => {
            if (field.id === 'type') {
              return <td>{appStore.getTariffNameById(row[field.id])}</td>
            } else if (field.id === 'is_active') {
              return <td>{row[field.id] ? 'Active': ''}</td>
            } else if (field.id.indexOf('proxy') > -1) {
              return <td>{parseInt(row[field.id]/1024/1024)} MB</td>
            } else if (field.id.indexOf('date') > -1) {
              const date = new Date(row[field.id])
              return <td>{date.toLocaleString()}</td>
            } else if (field.id === 'delete') {
              return <td><span style={{color: 'rgb(254, 47, 47)', cursor: 'pointer'}} onClick={() => appStore.deleteDevice(row.id)}>Delete</span></td>
            } else {
              return <td>{row[field.id]}</td>
            }
          })}
        </tr>)}
      </table>
    )
  }
}

export default Table
