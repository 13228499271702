import React, { Component } from 'react'
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import Popup from '../../modules/common/Popup'
import Form from '../../modules/common/Form'
import styles from './SetPassword.module.scss'
import { Link, withRouter } from 'react-router-dom'

@withRouter
@inject('appStore')
@observer
class ChangePassword extends Component {
  @observable _loading = false

  _handleSubmit = (fields) => {
    const {appStore, match} = this.props

    this._errors = null
    this._loading = true

    let params = {}
    fields.forEach(field => {
      params[field.name] = field.value
    })

    appStore.changePassword(params).then(r=> {
      this.props.history.push('/')
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        this._errors = e.response.data
      }

      this._loading = false
    })
  }

  render () {
    const fields = [{
      type: 'password',
      name: 'old_password',
      placeholder: 'Old Password'
    }, {
      type: 'password',
      name: 'password',
      placeholder: 'New Password'
    }, {
      type: 'password',
      name: 'password_confirm',
      placeholder: 'Confirm new password'
    }]

    return (
      <div className={styles.Recover}>
        <Popup title={'Change password'} type={'yellow'} footer={<p style={{color: '#494949', fontSize: 16, lineHeight: 1.5}}>If you have requested password change by mistake just close this page.</p>}>
          <Form fields={fields} errors={this._errors} loading={this._loading} button={'Submit'} handleSubmit={this._handleSubmit} />
        </Popup>
      </div>
    )
  }
}

export default ChangePassword
