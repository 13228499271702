import React, { Component } from 'react'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import styles from './Confirm.module.scss'
import Popup from '../../modules/common/Popup'
import popupStyles from '../../modules/common/Popup/Popup.module.scss'
import ConfirmIcon from '../../modules/common/Popup/images/confirm_icon.svg'
import Button from '../../modules/common/Button'

@inject('appStore')
@observer
class Confirm extends Component {
  @observable _loading = true

  constructor (props) {
    super(props)

    const url = new URL(document.location.href)


    props.appStore.confirm({
      user_id: url.searchParams.get('user_id'),
      timestamp: url.searchParams.get('timestamp'),
      signature: url.searchParams.get('signature')
    })
  }

  render () {
    return (
      <div className={styles.Confirm}>
        <Popup>
          <div className={popupStyles.Popup__icon}><img src={ConfirmIcon} /></div>
          <p className={popupStyles.Popup__text}>Your <b>email address</b> successfully verified.</p>
          <Button title={'Ok'} onClick={() => this.props.history.push('/signin')}  />
        </Popup>
      </div>
    )
  }
}

export default Confirm
