import React, {Component} from 'react'
import styles from './Button.module.scss'

class Button extends Component {
  render () {
    const {title, type = 'button', onClick, size, style, loading, className} = this.props
    return (
      <button type={type} style={style || {}} className={className ? className : styles['Button' + (size ? '__'+size : '__default')]} onClick={() => {
        if (!loading && onClick) {
          onClick()
        }
      }}>
        {loading ? <div className={styles.Loader} /> : title}
      </button>
    )
  }
}

export default Button
