import React, { Component } from 'react'
import {
  Button,
  Card,
  Col,
  Container, Form, Nav, Navbar, Row,
} from 'react-bootstrap'

class NoMatchPage extends Component {
  render () {
    return (
      <>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="#home">Mole Tunnels</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="#home">Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Container style={{marginTop: 30}}>
          <h1>404</h1>
        </Container>
      </>
    )
  }
}

export default NoMatchPage
