import React, { Component } from 'react'
import Popup from '../../modules/common/Popup'
import styles from './Success.module.scss'
import Button from '../../modules/common/Button'
import SuccessImage from './images/success.svg'

class Success extends Component {
  render () {

    const { match } = this.props

    const { plan } = match.params

    return (
      <div className={styles.Success}>
        <Popup>
          <img src={SuccessImage} />
          <h2 className={styles.Success__title}>You've successfully activated <b style={{textTransform: 'capitalize'}}>{plan}</b> plan. Thank you!</h2>
          <Button title={'OPEN DASHBOARD'} onClick={() => this.props.history.push('/')} />
        </Popup>
      </div>
    )
  }
}

export default Success
