import React, { Component } from 'react'

class oAuth extends Component {
  componentDidMount () {
    const url = new URL(document.location.href)
    const splitedPathname = url.pathname.split('/')

    window.opener.postMessage(JSON.stringify({
      code: url.searchParams.get('code'),
      provider: splitedPathname[splitedPathname.length - 1]
    }))

    window.close()
  }

  render () {
    return (
      <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999, backgroundColor: '#fff'}} />
    )
  }
}

export default oAuth
