import React, { Component } from 'react'
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import Popup from '../../modules/common/Popup'
import Form from '../../modules/common/Form'
import styles from './SetPassword.module.scss'
import { Link, withRouter } from 'react-router-dom'

@withRouter
@inject('appStore')
@observer
class SetPassword extends Component {
  @observable _loading = false

  _handleSubmit = (fields) => {
    const {appStore} = this.props
    this._loading = true
    this._errors = null

    const url = new URL(document.location.href)

    let password,
      confirmPassword

    fields.forEach(field => {
      if (field.name === 'password') {
        password = field.value
      }

      if (field.name === 'password_confirm') {
        confirmPassword = field.value
      }
    })

    if (password !== confirmPassword) {
      this._errors = {
        detail: 'Passwords don\'t match'
      }
      this._loading = false
      return
    }


    appStore.setPassword(password, {
      user_id: url.searchParams.get('user_id'),
      timestamp: url.searchParams.get('timestamp'),
      signature: url.searchParams.get('signature')
    }).then(r=> {
      this.props.history.push('/signin')
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        this._errors = e.response.data
      }

      this._loading = false
    })
  }

  render () {
    const fields = [{
      type: 'password',
      name: 'password',
      placeholder: 'New Password'
    }, {
      type: 'password',
      name: 'password_confirm',
      placeholder: 'Confirm new password'
    }]

    return (
      <div className={styles.Recover}>
        <Popup title={'New Password'} type={'yellow'} footer={<p style={{color: '#494949', fontSize: 16, lineHeight: 1.5}}>If you have requested password change by mistake just close this page.</p>}>
          <Form fields={fields} errors={this._errors} loading={this._loading} button={'Submit'} handleSubmit={this._handleSubmit} text={'Enter your new password.'} />
        </Popup>
      </div>
    )
  }
}

export default SetPassword
