import React, {Component} from 'react'
import styles from './Section.module.scss'
import Blocked from '../Blocked'

class Section extends Component {
  render () {
    const {title, children, noPadding, disabled} = this.props

    return (
      <div className={styles.Section}>
        <div className={styles.Section__title}>{title}</div>
        <div className={styles.Section__card}>
          {disabled && <Blocked reason={disabled} />}
          {children.map ? children.map(child => child && <div className={styles.Section__row}  style={noPadding ? {padding: 0} : {}}>
            {child}
          </div>) : <div className={styles.Section__row}  style={noPadding ? {padding: 0} : {}}>
            {children}
          </div>}
        </div>
      </div>
    )
  }
}

export default Section
