/*global chrome*/
import React, { Component } from 'react'
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import Loader from '../../modules/common/Loader'
import Popup from '../../modules/common/Popup'
import Form from '../../modules/common/Form'
import styles from './Login.module.scss'
import { Link, Redirect } from 'react-router-dom'
import Socials from '../../modules/common/Socials'

@inject('appStore')
@observer
class Login extends Component {
  @observable _loading = false

  _handleSubmit = (fields) => {
    const {appStore} = this.props

    this._errors = null
    this._loading = true

    let login, password
    fields.forEach(field => {
      if (field.name === 'login') {
        login = field.value
      } else if (field.name === 'password') {
        password = field.value
      }
    })

    appStore.login(login, password).then(r=> {
      if (r.data.token) {
        appStore.setToken(r.data.token)
        appStore.getCurrentUser().then(r=>{
          this.props.history.push('/')
        })
        try {
          chrome.runtime.sendMessage('ioioabbadhlnjojnhhheddlbhjmmbfhd', {
            loginToken: r.data.token
          })
        } catch(e) {}
      } else {
        this._loading = false
      }
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        this._errors = e.response.data
      }

      this._loading = false
    })
  }

  handleOAuth = (provider, code, redirect_uri) => {
    const { appStore } = this.props
    this._loading = true
    appStore.loginOAuth(provider, code, redirect_uri).then(r=> {
      if (r.data.token) {
        appStore.setToken(r.data.token)
        appStore.getCurrentUser().then(r=>{
          this.props.history.push('/')
        })
        try {
          chrome.runtime.sendMessage('ioioabbadhlnjojnhhheddlbhjmmbfhd', {
            loginToken: r.data.token
          })
        } catch(e) {}
      } else {
        this._loading = false
      }
    })
    .catch((e) => {
      this._loading = false
    })
  }

  render () {
    const {appStore} = this.props

    const fields = [{
      type: 'text',
      name: 'login',
      placeholder: 'Email'
    }, {
      type: 'password',
      name: 'password',
      placeholder: 'Password'
    }]

    if (appStore.isUserLoggedIn && !this._loading) {
      return <Redirect to={'/'} />
    }

    return (
      <div className={styles.Login}>
        <Popup title={'Login'} footer={<Link to={'/recover'}>Forgot your password?</Link>}>
          <Socials handleOAuth={this.handleOAuth} title={<span>Login with <b>oAuth</b></span>} />
          <Form fields={fields} loading={this._loading} errors={this._errors} secondButton={{title: 'Sign Up', href: '/signup'}} button={'Sign in'} text={<p>Or use your <b>Email</b></p>} handleSubmit={this._handleSubmit} />
        </Popup>
      </div>
    )
  }
}

export default Login
