import React, {Component} from 'react'

class Loader extends Component {
  render () {
    return (
      <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} />
    )
  }
}

export default Loader
