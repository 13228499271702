import React, {Component} from 'react'
import styles from './Socials.module.scss'
import facebook from './images/facebook.svg'
import google from './images/google.svg'
import { inject } from 'mobx-react'

const cliend_id = '1079589468671-31m4j6blkfho0fk7f1so5ah56gt1npho.apps.googleusercontent.com'
//const redirect_uri = 'http://127.0.0.1:3000/o/google-oauth2'
const redirect_uri = 'https://my.moletunnels.net/o/google-oauth2'


@inject('appStore')
class Socials extends Component {
  popupCenter = ({url, title, w, h}) => {
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )


    if (window.focus) newWindow.focus();
  }

  handleToken = (e) => {
    const { handleOAuth } = this.props
    window.removeEventListener('message', this.handleToken)

    const data = JSON.parse(e.data)
    handleOAuth(data.provider,decodeURIComponent(data.code), redirect_uri)
  }

  handleGoogle = () => {
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${cliend_id}&redirect_uri=${redirect_uri}&state=profile&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email&access_type=offline&approval_prompt=auto`
    this.popupCenter({
      url,
      title: 'Google oAuth2',
      w: 600,
      h: 600
    })
    window.addEventListener('message', this.handleToken)
  }

  render () {
    const { title } = this.props

    return (
      <div className={styles.Socials}>
        <div className={styles.Socials__title}>{title}</div>
        <div className={styles.Socials__buttons}>
          {/*<div className={styles.Socials__button}>*/}
          {/*  <img src={facebook} />*/}

          {/*  Facebook*/}
          {/*</div>*/}
          <div className={styles.Socials__button} onClick={this.handleGoogle}>
            <img src={google} />

            Google
          </div>
        </div>
      </div>
    )
  }
}

export default Socials
