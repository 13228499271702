import React, {Component} from 'react'
import logoImage from './images/logo.svg'
import { Link } from 'react-router-dom'

class Logo extends Component {
  render () {
    const {title} = this.props

    return (
      <Link to={'/'} style={{fontSize: 0, display: 'block'}}><img src={logoImage} /></Link>
    )
  }
}

export default Logo
