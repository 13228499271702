import React, {Component} from 'react'
import styles from './Radio.module.scss'

class Radio extends Component {
  render () {
    const {title, value, name} = this.props

    return (
      <div className={styles.Radio}>
        <input name={name} value={value} id={value} type={'radio'} />
        <label htmlFor={value}>{title}</label>
      </div>
    )
  }
}

export default Radio
