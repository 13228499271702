import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Section from '../../modules/common/Section'
import styles from './Home.module.scss'
import Button from '../../modules/common/Button'
import Radio from '../../modules/common/Radio'
import Table from '../../modules/common/Table'
import { NavLink, Redirect } from 'react-router-dom'
import SocialRow from '../../modules/common/SocialRow'

@inject('appStore')
@observer
class Home extends Component {
  render () {
    const {appStore} = this.props

    if (!appStore.isUserLoggedIn) {
      return <Redirect to={'/signup'} />
    }

    const activePayment = appStore.user.active_payment

    const used = parseInt((activePayment.proxy_in+activePayment.proxy_out)/1024/1024)
    const total = appStore.user.currentTariff.total_limit === 0 ? '∞' : parseInt(appStore.user.currentTariff.total_limit/1024/1024)

    let remainingPercent = 100 - ((used/total*100) || 0)

    if (remainingPercent < 0) {
      remainingPercent = 0
    }

    const disabled = appStore.user.disabled_reason

    return (
      <div className={styles.Home}>
        <div className={styles.Home__inner}>
          {!disabled && <Section title={`${appStore.user.currentTariff.name} Plan`}>
            <div className={styles.Remainings}>
              <div className={styles.Remainings__top}>
                <div className={styles.Remainings__left}>
                  <b>{appStore.user.currentTariff.total_limit === 0 ? '∞' : ((total - used) < 0 ? 0 : total - used)} MB</b> remaining this month
                </div>
                <div className={styles.Remainings__right}>
                  {total} MB
                </div>
              </div>
              <div className={styles.Remainings__progress}>
                <div className={styles.Remainings__used} style={{backgroundColor: remainingPercent < 10 ? '#FE2F2F' : '#33c02a' , width: (remainingPercent < 3 ? 3 : remainingPercent)+'%'}} />
              </div>
            </div>
            {appStore.user.currentTariff.total_limit !== 0 && <div style={{display:'flex', flexDirection: 'column', alignItems: 'center'}}>
              {remainingPercent === 0 && <div className={styles.Remainings__text}>You have used free {total} MB of traffic. <span>To continue using the service upgrade your plan please</span>.</div>}
              <Button title={'UPGRADE PLAN'} size={'medium'} style={{maxWidth: 320}} onClick={() => {
                window.open('https://moletunnels.net/pricing/')
              }} />
            </div>}
          </Section>}
          <Section title={disabled ? <div>Account <span>Disabled</span></div> : 'Account'} disabled={disabled}>
            {appStore.user.socials.length === 0 &&
            <div className={styles.Default}>
              <div className={styles.Default__left}>
                <div className={styles.Default__title}>Email</div>
                <div
                  className={styles.Default__text}>{appStore.user.email}</div>
              </div>
            </div>
            }
            {appStore.user.socials.length === 0 &&
              <div className={styles.Default}>
                <div className={styles.Default__left}>
                  <div className={styles.Default__title}>Password</div>
                  <div className={styles.Default__text}>**********</div>
                </div>
                <NavLink to="/change-password" className={styles.Default__button}>
                  change
                </NavLink>
              </div>
            }
            {appStore.user.socials.length ? appStore.user.socials.map(social => <SocialRow data={social} />) : null}
            <div style={{textAlign: 'center', padding: '20px 0px'}}>
              <a href={'#'} style={{color: '#FE2F2F', fontWeight: 'bold', fontSize: 15, textDecoration: 'none', display: 'inline-block'}} onClick={(e) => {
                e.preventDefault()
                appStore.logout().then(r=>{
                  this.props.history.push('/signin')
                })
              }}>LOG OUT</a>
            </div>
          </Section>
          <Section title={'Billing History'} noPadding={appStore.user.payments.length > 0}>
            {appStore.user.payments.length > 0 ? <Table fields={[{title: 'Type', id: 'type'},{title: 'Proxy In', id: 'proxy_in'},{title: 'Proxy Out', id: 'proxy_out'},{title: 'Activate Date', id: 'start_date'}, {title: 'Status', id: 'status'}]} rows={appStore.user.payments} /> : <div style={{color: '#91979F', fontSize: 17, padding: '50px 0px', textAlign: 'center'}}>
              You don't have any billing history yet.
            </div>}
            {appStore.user.payments.length > 1 && <div style={{ margin: '20px', textAlign: 'center' }}>
              <div className={styles.Default__button} onClick={() => {
                appStore.manageSubscriptions().then(r => {
                  document.location.href = r.data.url
                })
              }}>
                manage
              </div>
            </div>}
          </Section>
          <Section title={'Devices'} noPadding={appStore.user.devices.length > 0}>
            {appStore.user.devices.length > 0 ? <Table fields={[{title: 'IP', id: 'ip'},{title: 'User Agent', id: 'ua'}, {title: 'Delete', id: 'delete'}]} rows={appStore.user.devices} /> : <div style={{color: '#91979F', fontSize: 17, padding: '50px 0px', textAlign: 'center'}}>
              You don't have any devices yet.
            </div>}
          </Section>
        </div>
      </div>
    )
  }
}

export default Home
