import React, {Component} from 'react'
import { Redirect, Route } from 'react-router-dom'
import {observer, inject} from 'mobx-react'


@inject('appStore')
@observer
class PrivateRoute extends Component {
  render () {
    const {appStore, component} = this.props
    return (
      <Route render={(props) => {
        if (appStore.isUserLoggedIn) {
          const PrivateComponent = component
          return <PrivateComponent {...props} />
        } else {
          return <Redirect to='/signup' />
        }
      }} />
    )
  }
}

export default PrivateRoute
