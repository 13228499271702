import { getParent, types } from 'mobx-state-tree'

const Device = types
.model('Device', {
  id: types.maybe(types.number),
  ua: types.optional(types.string, ''),
  ip: types.optional(types.string, '')
})
.views(self => ({

}))
.actions(self => ({

}))

const Payment = types
.model('Payment', {
  type: types.maybe(types.number),
  is_active: types.optional(types.boolean, false),
  state: types.optional(types.string, ''),
  proxy_in: types.optional(types.number, 0),
  proxy_out: types.optional(types.number, 0),
  start_date: types.optional(types.string, ''),
  status: types.optional(types.string, '')
})
.views(self => ({

}))
.actions(self => ({

}))

const Social = types
.model('Payment', {
  provider: types.optional(types.string, ''),
  uid: types.optional(types.string, '')
})
.views(self => ({

}))
.actions(self => ({

}))

const User = types
.model('User', {
  email: types.optional(types.string, ''),
  disabled_reason: types.maybeNull(types.string),
  devices: types.optional(types.array(Device), []),
  payments: types.optional(types.array(Payment), []),
  socials: types.optional(types.array(Social), []),
  active_payment: types.maybeNull(Payment)
})
.views(self => ({
  get appStore() {
    return getParent(self)
  },

  get currentTariff() {
    return self.appStore.tariffs.filter(tariff => self.active_payment && self.active_payment.type === tariff.id)[0] || {}
  },

}))
.actions(self => ({

}))


export default User
