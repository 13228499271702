import React, {Component} from 'react'
import styles from './Form.module.scss'
import ButtonStyles from '../Button/Button.module.scss'
import Button from '../Button'
import {observer} from 'mobx-react'
import { observable } from 'mobx'
import { withRouter } from 'react-router-dom'

@withRouter
@observer
class Form extends Component {
  @observable _fields = []

  constructor (props) {
    super(props)

    props.fields.forEach(field => this._fields.push({
      name: field.name,
      type: field.type,
      placeholder: field.placeholder,
      value: ''
    }))
  }

  _handleChange = (name, value) => {
    this._fields.forEach(field => {
      if (field.name === name) {
        field.value = value
      }
    })
  }

  render () {
    const {button, text, handleSubmit, errors, loading, secondButton} = this.props

    return (
      <form className={styles.Form} onSubmit={e => {
        e.preventDefault()
        handleSubmit(this._fields.toJS())
      }}>
        <div className={styles.Form__body}>
          {text ? <div className={styles.Form__text}>
            {text}
          </div> : null}
          {this._fields.map((field,index) => <div className={styles.Form__row} key={index}>
            <input type={field.type} className={styles.Form__input} value={field.value} onChange={e => this._handleChange(field.name, e.target.value)} placeholder={field.placeholder} />
            {errors && errors[field.name] && <div className={styles.Form__error} key={index}>{errors[field.name][0]}</div>}
          </div>)}
        </div>
        <Button title={button} type={'submit'} loading={loading} />
        {secondButton && <Button title={secondButton.title} className={ButtonStyles.Button__secondary} type={'submit'} onClick={() => this.props.history.push(secondButton.href)} />}
        {errors && errors['non_field_errors'] && <div className={styles.Form__errors}>
          {errors['non_field_errors'].map((error, index) => <div className={styles.Form__error} key={index}>{error}</div> )}
        </div> }
        {errors && errors['detail'] && <div className={styles.Form__errors}>
          <div className={styles.Form__error}>{errors['detail']}</div>
        </div>}
      </form>
    )
  }
}

export default Form
