import React, { Component } from 'react'
import { Provider } from 'mobx-react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import appStore from './stores/AppStore'
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import NoMatchPage from './pages/NoMatchPage'
import PrivateRoute from './modules/common/PrivateRoute'
import {observer} from 'mobx-react'
import './index.css'
import Loader from './modules/common/Loader'
import Logout from './pages/Logout'
import Header from './modules/common/Header'
import Recover from './pages/Recover'
import SetPassword from './pages/SetPassword'
import CookiesAlert from './modules/common/CookiesAlert'
import Error from './modules/common/Error'
import Confirm from './pages/Confirm'
import ChangePassword from './pages/ChangePassword'
import oAuth from './pages/oAuth'
import Success from './pages/Success'

@observer
class App extends Component {
  render () {
    if (appStore.loading) {
      return <Loader/>
    }

    return (
      <BrowserRouter>
        <Provider appStore={appStore}>
          <Header />
          {appStore.error && <Error text={appStore.error}/>}

          <Switch>
            <Route path="/o" component={oAuth} />
            <Route path="/confirm" component={Confirm} exact/>
            <Route path="/logout" component={Logout} exact/>
            <Route path="/set-password" component={SetPassword} exact/>
            <Route path="/success/:plan?" component={Success} exact/>
            <Route path="/recover" component={Recover} exact/>
            <Route path="/change-password" component={ChangePassword} exact/>
            <Route path="/signin" component={Login} exact/>
            <Route path="/signup" component={Signup} exact/>
            <PrivateRoute path='/' component={Home} />
            <Route component={NoMatchPage} />
          </Switch>
          {appStore.isCookiesAlertShown && <CookiesAlert />}
        </Provider>
      </BrowserRouter>
    )
  }
}

export default App
