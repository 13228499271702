import React, { Component } from 'react'
import {observer, inject} from 'mobx-react'
import {observable} from 'mobx'
import Loader from '../../modules/common/Loader'
import Popup from '../../modules/common/Popup'
import Form from '../../modules/common/Form'
import styles from './Recover.module.scss'
import popupStyles from '../../modules/common/Popup/Popup.module.scss'
import RecoverIcon from '../../modules/common/Popup/images/recover_icon.svg'
import { Link, withRouter } from 'react-router-dom'
import Button from '../../modules/common/Button'

@withRouter
@inject('appStore')
@observer
class Recover extends Component {
  @observable _email = ''
  @observable _password = ''
  @observable _loading = false
  @observable _success = false

  _handleSubmit = (fields) => {
    const {appStore} = this.props

    this._errors = null
    this._loading = true

    let email
    fields.forEach(field => {
      if (field.name === 'email') {
        email = field.value
      }
    })

    appStore.recover(email).then(r=> {
      this._success = true
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        this._errors = e.response.data
      }

      this._loading = false
    })
  }

  render () {

    const fields = [{
      type: 'email',
      name: 'email',
      placeholder: 'Email'
    }]

    return (
      <div className={styles.Recover}>
        {!this._success && <Popup title={'Reset Password'} type={'red'} footer={<Link to={'/signin'}>Go back</Link>}>
          <Form fields={fields} button={'Reset'} loading={this._loading} errors={this._errors} handleSubmit={this._handleSubmit} />
        </Popup>}
        {this._success && <Popup>
          <div className={popupStyles.Popup__icon}><img src={RecoverIcon} /></div>
          <p className={popupStyles.Popup__text}>We've just sent a <b>password reset link</b> your email.</p>
          <Button title={'Ok'} onClick={() => this.props.history.push('/signin')}  />
        </Popup>}
      </div>
    )
  }
}

export default Recover
