/*global chrome*/
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import Loader from '../../modules/common/Loader'
import styles from './Signup.module.scss'
import Popup from '../../modules/common/Popup'
import Form from '../../modules/common/Form'
import popupStyles from '../../modules/common/Popup/Popup.module.scss'
import RecoverIcon from '../../modules/common/Popup/images/recover_icon.svg'
import Button from '../../modules/common/Button'
import Socials from '../../modules/common/Socials'

@inject('appStore')
@observer
class Signup extends Component {
  @observable _loading = false
  @observable _success = false

  _handleSubmit = (fields) => {
    const {appStore} = this.props

    this._errors = null
    this._loading = true

    let email, password, password_confirm

    fields.forEach(field => {
      if (field.name === 'email') {
        email = field.value
      } else if (field.name === 'password') {
        password = field.value
      } else if (field.name === 'password_confirm') {
        password_confirm = field.value
      }
    })

    appStore.signup(email, password, password_confirm).then(r=> {
      // if (r.data.token) {
      //   appStore.setToken(r.data.token)
      //   appStore.getCurrentUser().then(r=>{
      //     this.props.history.push('/')
      //   })
      // } else {
      //   this._loading = false
      // }
      this._success = true
    })
    .catch((e) => {
      if (e.response && e.response.data) {
        this._errors = e.response.data
      }

      this._loading = false
    })
  }

  handleOAuth = (provider, code, redirect_uri) => {
    const { appStore } = this.props
    this._loading = true
    appStore.loginOAuth(provider, code, redirect_uri).then(r=> {
      if (r.data.token) {
        appStore.setToken(r.data.token)
        appStore.getCurrentUser().then(r=>{
          this.props.history.push('/')
        })
        try {
          chrome.runtime.sendMessage('ioioabbadhlnjojnhhheddlbhjmmbfhd', {
            loginToken: r.data.token
          })
        } catch(e) {}
      } else {
        this._loading = false
      }
    })
      .catch((e) => {
        this._loading = false
      })
  }


  render () {
    const fields = [{
      type: 'email',
      name: 'email',
      placeholder: 'Email'
    }, {
      type: 'password',
      name: 'password',
      placeholder: 'Password'
    }, {
      type: 'password',
      name: 'password_confirm',
      placeholder: 'Confirm Password'
    }]

    return (
      <div className={styles.Signup}>
        {!this._success && <Popup title={'Create an account'} type={'yellow'} footer={<p>By creating account you agree to the <a href={'https://moletunnels.net/terms/'} target={'_blank'}>Terms of Service</a> and <a href={'https://moletunnels.net/privacy/'} target={'_blank'}>Privacy Policy</a></p>}>
          <Socials handleOAuth={this.handleOAuth} title={<span>Sign up with <b>oAuth</b></span>} />
          <Form fields={fields} button={'Sign Up'} secondButton={{title: 'Sign In', href: '/signin'}} loading={this._loading} errors={this._errors} handleSubmit={this._handleSubmit} text={<p>Or use your <b>Email</b> to create an account</p>} />
        </Popup>}
        {this._success && <Popup>
          <div className={popupStyles.Popup__icon}><img src={RecoverIcon} /></div>
          <p className={popupStyles.Popup__text}>We've just sent a <b>verification link</b> to your email.</p>
          <Button title={'Ok'} onClick={() => this.props.history.push('/signin')}  />
        </Popup>}
      </div>
    )
  }
}

export default Signup
